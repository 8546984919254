module.exports = sortByProcedure;

/*
	sort the parts of the passed selector,
	as there is potential for optimization
	(some types of selectors are faster than others)
*/

var procedure = require("./procedure.json");

var ATTRIBUTE = procedure.attribute;

var attributes = {
	__proto__: null,
	exists: 8,
	equals: 7,
	not: 6,
	start: 5,
	end: 4,
	any: 3,
	hyphen: 2,
	element: 1
};

function sortByProcedure(arr){
	for(var i = 1; i < arr.length; i++){
		var procNew = procedure[arr[i].type];

		if(procNew < 0) continue;

		for(var j = i - 1; j >= 0; j--){
			if(
				procNew > procedure[arr[j].type] || !(
					procNew === ATTRIBUTE &&
					procedure[arr[j].type] === ATTRIBUTE &&
					attributes[arr[i].action] <= attributes[arr[j].action]
				)
			) break;

			var tmp = arr[j + 1];
			arr[j + 1] = arr[j];
			arr[j] = tmp;
		}
	}
}